import gql from "graphql-tag.macro";

export const m_status_update = gql`
  mutation invoice_status_update ($id: ID!, $status: String!, $id_user: ID!) {
    invoice_status_update (id: $id, status: $status, id_user: $id_user) {
      id
    }
  },
`;

export const m_outer_box_create = gql`
  mutation outer_box_create ($input: OuterBoxCreateInput!) {
    outer_box_create (input: $input) {
      id
    }
  }
`;

export const m_outer_box_update = gql`
  mutation outer_box_update ($id: ID!, $input: OuterBoxUpdateInput!) {
    outer_box_update (id: $id, input: $input) {
      id
    }
  }
`;

export const m_outer_box_delete = gql`
  mutation outer_box_delete ($id: ID!) {
    outer_box_delete (id: $id) {
      id
    }
  }
`;

export const m_inner_box_create = gql`
  mutation inner_box_create ($input: InnerBoxCreateInput!) {
    inner_box_create (input: $input)
  }
`;

export const m_inner_box_delete = gql`
  mutation inner_box_delete ($id: ID!) {
    inner_box_delete (id: $id) {
      id
    }
  }
`;

export const m_gauge_line_weight = gql`
  mutation gauge_line_edit_weight ($id: ID!, $weight: Float!) {
    gauge_line_update (id: $id, input: { weight: $weight }) {
      id
    }
  }
`;

export const m_gauge_line_gauge_packing_scan = gql`
  mutation gauge_line_gauge_packing_scan ($id_gauge: ID!, $id_employee: ID!, $id_outer_box: ID!) {
    gauge_line_gauge_packing_scan (id_gauge: $id_gauge, id_employee: $id_employee, id_outer_box: $id_outer_box) {
      id
    }
  }
`;

export const m_inventory_sale_line_packing_pack_outer_box = gql`
  mutation inventory_sale_line_packing_pack_outer_box ($id_outer_box: ID!, $id_packed_by: ID!) {
    inventory_sale_line_packing_pack_outer_box (id_outer_box: $id_outer_box, id_packed_by: $id_packed_by)
  }
`;

export const q_boxes_list = gql`
  query boxes ($filter: BoxFilterInput) {
    boxes (limit: 10000, filter: $filter, sort: { id: ASC }) {
      id
      notes
    }
  }
`;

export const q_detail = gql`
  query invoice_dispatch ($id: ID!) {
    invoice (id: $id) {
      id
      invoice_date
      freight_carrier
      freight_mode
      freight_terms
      airwaybill_number
      status
      customer_shipping {
        id
        name
      }
      address_shipping {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      quantity
      inventory_sale_lines {
        id
        id_sales_order
        line
        quantity
        inventory {
          id
          model_code
          weight
        }
        production_status {
          qty_assigned_box
          qty_packed
        }
        packings {
          quantity
          packed_at
          inner_box {
            id_box
            outer_box {
              box_number
            }
          }
        }
      }
      gauge_lines {
        id
        id_sales_order
        line
        model_code
        quantity
        weight
        production_status {
          qty_assigned_box
          qty_packed
        }
        gauges {
          id
          line_item
          packed_at
          inner_box {
            id_box
            outer_box {
              box_number
            }
          }
        }
      }
      outer_boxes {
        id
        box_number
        tare_weight
        id_box
        box {
          id
          notes
          weight
        }
        packing_details {
          net_weight
          gross_weight
          quantity
          qty_packed
        }
        inner_boxes {
          id
          id_box
          packing_details {
            net_weight
            gross_weight
            qty_packed
          }
          gauges {
            id
            packed_at
          }
          inventories {
            id_inventory_sale_line
            quantity
            packed_at
          }
        }
      }
    }
  },
`;

export const q_list = gql`
  query invoices ($limit: Int!, $offset: Int!, $filter: InvoiceFilterInput, $sort: [InvoiceSortInput!]) {
    list: invoices (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      customer_billing {
        name
      }
      invoice_date
      packing_status {
        qty_total
        qty_assigned_box
        qty_packed
      }
    }
  }
`;
